import { Component, Vue, Prop } from 'vue-property-decorator';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Report } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { debounce } from 'lodash';
import { Rpc } from '@/models/Rpc';
import { ReportStatusMap, excludePlanningStatuses } from '@/support/ReportStatus';

@Component<SupportDialog>({})
export default class SupportDialog extends Vue {
  @Prop({ default: null })
  protected type !: string;

  @Prop({ default: null })
  protected signature !: string;

  @Prop({ default: null })
  protected additional !: string | boolean;

  @Prop({ default: null })
  protected typeExplanation !: string;

  protected isLoading = false;

  protected reportStatusMap: ReportStatusMap[] = excludePlanningStatuses();

  protected reports: Report[] = [];

  protected selectedReport: Report | null = null;

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);

  protected reportStatus: ReportStatusMap | null = null;

  protected parsedStatuses: any = [];

  public mounted() {
    //
  }

  protected handleConfirm() {
    this.$store.dispatch('openDialog', this.dialogOptions);
  }

  protected close() {
    this.$emit('input', false);
  }

  protected handleSearch(query: any) {
    if (! query || ! query.target || ! query.target.value) {
      return;
    }

    new Report()
      .filter('search', query.target.value)
      .limit(15)
      .all()
      .then((response: Report[]) => {
        this.reports = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get dialogOptions(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet u zeker dat u dit rapport wilt ${this.typeExplanation}?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.typeExplanation,
          color: 'primary',
          action: () => {
            if (! this.selectedReport) {
              return;
            }

            const payload: any = {
              signature: this.signature,
              body: {
                report: this.selectedReport.case_number,
              },
            };

            if (this.reportStatus) {
              payload.body.status = this.reportStatus.key;
            }

            if (this.type === 'finalstate') {
              payload.body.is_final = this.selectedReport.is_final;
            }

            if (this.type === 'syncing_status') {
              payload.body.is_syncing = this.selectedReport.is_syncing;
            }

            new Rpc()
              .rpcPost(payload)
              .then((response: any) => {
                if (this.type === 'schade') {
                  if (! this.selectedReport) {
                    return;
                  }

                  this.$emit('responseDamagesForReport', {
                    reportCaseNumber: this.selectedReport.case_number,
                    result: response.response,
                  });
                }
                this.close();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }
}
