var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-layout",
            {
              staticClass: "elementPanel__header",
              attrs: { row: "", wrap: "" }
            },
            [
              _c(
                "v-flex",
                { staticClass: "header__title", attrs: { sm11: "" } },
                [_c("h2", [_vm._v("Rapport " + _vm._s(_vm.typeExplanation))])]
              ),
              _c(
                "v-flex",
                { staticClass: "header__close", attrs: { sm1: "" } },
                [
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.close($event)
                        }
                      }
                    },
                    [_vm._v("cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "v-card-text",
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "250px"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-space-between": "",
                    "align-baseline": "",
                    "mb-4": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "field", attrs: { xs12: "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.reports,
                          label: "Case number",
                          "item-text": "case_number",
                          "item-value": "uuid",
                          "hide-details": "",
                          "return-object": ""
                        },
                        on: { keyup: _vm.debouncedSearch },
                        model: {
                          value: _vm.selectedReport,
                          callback: function($$v) {
                            _vm.selectedReport = $$v
                          },
                          expression: "selectedReport"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.type === "status"
                    ? _c(
                        "v-flex",
                        { staticClass: "field", attrs: { xs12: "" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.reportStatusMap,
                              label: "Status",
                              "item-text": "label",
                              "item-value": "key",
                              "hide-details": "",
                              "return-object": "",
                              disabled: !_vm.selectedReport
                            },
                            model: {
                              value: _vm.reportStatus,
                              callback: function($$v) {
                                _vm.reportStatus = $$v
                              },
                              expression: "reportStatus"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "finalstate"
                    ? _c(
                        "v-flex",
                        { staticClass: "field", attrs: { xs12: "" } },
                        [
                          _vm.selectedReport
                            ? _c("v-switch", {
                                attrs: { label: "is een definitieve dossier" },
                                model: {
                                  value: _vm.selectedReport.is_final,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedReport,
                                      "is_final",
                                      $$v
                                    )
                                  },
                                  expression: "selectedReport.is_final"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "syncing_status"
                    ? _c(
                        "v-flex",
                        { staticClass: "field", attrs: { xs12: "" } },
                        [
                          _vm.selectedReport
                            ? _c("v-switch", {
                                attrs: { label: "Syncing?" },
                                model: {
                                  value: _vm.selectedReport.is_syncing,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedReport,
                                      "is_syncing",
                                      $$v
                                    )
                                  },
                                  expression: "selectedReport.is_syncing"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c(
            "v-layout",
            { attrs: { "justify-space-between": "", row: "" } },
            [
              _c(
                "MiButton",
                {
                  attrs: { color: "text-light", outline: "true" },
                  nativeOn: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.close($event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$tc("general.cancel")) + " ")]
              ),
              _c(
                "MiButton",
                {
                  staticClass: "float--right",
                  nativeOn: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleConfirm($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.typeExplanation))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }