var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
                    _c("h2", { staticClass: "elementSubTitle" }, [
                      _vm._v("Algemene acties")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel elementPanel--spaced" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Verander de status van het rapport naar de nieuw aangegeven status. De status wordt in alle omgevingen waar het rapport voorkomt aangepast."
                                    )
                                  ])
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-sm-right text-xs-center",
                                    attrs: { xs12: "", sm4: "" }
                                  },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.openSupportDialog(
                                              "status",
                                              "aanpassen",
                                              "report:status"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Status aanpassen")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel elementPanel--spaced" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Verander de is_syncing status van een rapport"
                                    )
                                  ])
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-sm-right text-xs-center",
                                    attrs: { xs12: "", sm4: "" }
                                  },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.openSupportDialog(
                                              "syncing_status",
                                              "aanpassen",
                                              "report:set-syncing-status"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Status aanpassen")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
                    _c("h2", { staticClass: "elementSubTitle" }, [
                      _vm._v("Acties alleen op de IMG omgeving")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel elementPanel--spaced" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Rapport wordt opnieuw gesynchroniseerd met de gegevens uit de deskundige bureau, terug naar IMG."
                                    )
                                  ])
                                ]),
                                _vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "text-sm-right text-xs-center",
                                        attrs: { xs12: "", sm4: "" }
                                      },
                                      [
                                        _c(
                                          "MiButton",
                                          {
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.openSupportDialog(
                                                  "sync",
                                                  "synchroniseren",
                                                  "report:reload"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Synchroniseren")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
                    _c("h2", { staticClass: "elementSubTitle" }, [
                      _vm._v("Acties alleen op een Expert bureau omgeving")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel elementPanel--spaced" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Hergenereer de images, inclusief thumbnails en finalize het rapport. Daarna wordt hij automatisch naar IMG overgezet."
                                    )
                                  ])
                                ]),
                                !_vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "text-sm-right text-xs-center",
                                        attrs: { xs12: "", sm4: "" }
                                      },
                                      [
                                        _c(
                                          "MiButton",
                                          {
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.openSupportDialog(
                                                  "regenerateandtransfer",
                                                  "hergenereer",
                                                  "report:regenerate-and-transfer"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Hergenereren")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel elementPanel--spaced" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Verander de definitieve status van het rapport naar de nieuw aangegeven status."
                                    )
                                  ])
                                ]),
                                !_vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-flex",
                                      {
                                        staticClass:
                                          "text-sm-right text-xs-center",
                                        attrs: { xs12: "", sm4: "" }
                                      },
                                      [
                                        _c(
                                          "MiButton",
                                          {
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.openSupportDialog(
                                                  "finalstate",
                                                  "aanpassen",
                                                  "report:final"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Status aanpassen")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
                    _c("h2", { staticClass: "elementSubTitle" }, [
                      _vm._v("Schades")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel elementPanel--spaced" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Haal hier de informatie op van alle schades. Per schade wordt getoond of alles goed is ingevuld."
                                    )
                                  ])
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-sm-right text-xs-center",
                                    attrs: { xs12: "", sm4: "" }
                                  },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.openSupportDialog(
                                              "schade",
                                              "ophalen",
                                              "report:calculate-damages"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Rapport ophalen")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm.reportCaseNumber
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
                        _c("h2", { staticClass: "elementSubTitle" }, [
                          _vm._v(
                            "Resultaat van alle schades voor rapport met case number: " +
                              _vm._s(_vm.reportCaseNumber)
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
                        _c(
                          "div",
                          { staticClass: "elementPanel elementPanel--spaced" },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { "align-center": "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm8: "" } },
                                      [
                                        _vm.damagesForReport
                                          ? _c("span", [
                                              _c("pre", [
                                                _vm._v(
                                                  "                    " +
                                                    _vm._s(
                                                      _vm.damagesForReport
                                                    ) +
                                                    "\n                  "
                                                )
                                              ])
                                            ])
                                          : _c("span", [
                                              _vm._v("Geen resultaten")
                                            ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
                    _c("h2", { staticClass: "elementSubTitle" }, [
                      _vm._v("Zienswijzes")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "", row: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "elementPanel elementPanel--spaced" },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "", wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
                                  _c("span", [
                                    _vm._v(
                                      "Zet schade plaatjes over van v1 naar v2 (indien er geen wijzigingen zijn in de schade aantallen + namen)"
                                    )
                                  ])
                                ]),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "text-sm-right text-xs-center",
                                    attrs: { xs12: "", sm4: "" }
                                  },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.openSupportDialog(
                                              "zienswijze",
                                              "goedzetten",
                                              "report:fix-zienswijze-images"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Plaatjes overzetten")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isOpeningSupportDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "750", persistent: true },
              model: {
                value: _vm.isOpeningSupportDialog,
                callback: function($$v) {
                  _vm.isOpeningSupportDialog = $$v
                },
                expression: "isOpeningSupportDialog"
              }
            },
            [
              _c("SupportDialog", {
                attrs: {
                  type: _vm.type,
                  signature: _vm.signature,
                  typeExplanation: _vm.typeExplanation,
                  additional: _vm.additional
                },
                on: {
                  responseDamagesForReport: _vm.setInfoForResultDamagesOfReport
                },
                model: {
                  value: _vm.isOpeningSupportDialog,
                  callback: function($$v) {
                    _vm.isOpeningSupportDialog = $$v
                  },
                  expression: "isOpeningSupportDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }