import { Component, Vue, Watch } from 'vue-property-decorator';
import SupportDialog from '@/views/Support/SupportDialog.vue';

@Component<Support>({
  components: {
    SupportDialog,
  },
})
export default class Support extends Vue {
  public $pageTitle = 'Support';

  protected type: string | null = null;

  protected signature: string | null = null;

  protected typeExplanation: string | null = null;

  protected additional?: string | boolean;

  protected isLoading = true;

  protected isOpeningSupportDialog = false;

  protected damagesForReport: string | null = null;

  protected reportCaseNumber: string | null = null;

  public created() {
    this.reRoute();
  }

  public mounted() {
    this.reRoute();
    this.emitBreadcrumb();

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Support' },
        ],
      });
  }

  protected reRoute() {
    if (! this.$store.state.Auth.isAtabix) {
      this.$router.push('/reports');
    }
  }

  protected openSupportDialog(type: string, typeExplanation: string, signature: string, additional?: string | boolean) {
    this.type = type;
    this.signature = signature;
    this.typeExplanation = typeExplanation;
    this.additional = additional;
    this.isOpeningSupportDialog = true;
  }

  protected setInfoForResultDamagesOfReport(response: {[key: string]: string}) {
    this.reportCaseNumber = response.reportCaseNumber;
    this.damagesForReport = response.result;
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
